/* eslint-disable no-else-return */
/* eslint-disable object-curly-newline */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Table, Button, Row, Col, Spinner } from 'react-bootstrap';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MainLayout from '../../components/main-layout';
import apiWrapper from '../../api';
import { tableTitles, setTableTitles, reviewAndConfirmMessage, setInstructionModalToggled } from '../../utils/roster';

const ReviewRoster = ({ location, history }) => {
  const [students, setStudents] = useState([]);
  const [sortingParams, setSortingParams] = useState({
    title: 'last_name',
    orderDescending: true,
  });
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  useEffect(() => {
    location.state.data !== undefined && location.state.data.length > 0 ? setStudents([...location.state.data]) : setStudents([...location.state.tableInput]);
    if (location.state !== undefined && location.state) setTableTitles(location.state.course.enrollment_type);
  }, []);

  const createAndSendInvitations = () => {
    setIsConfirmLoading(true);
    const data = location.state.data;
    const tableInput = location.state.tableInput;
    if (data !== undefined && data.length > 0) {
      data.forEach(async (student) => {
        const username = student[tableTitles[2].title] ? student[tableTitles[2].title].toLowerCase() : null;
        try {
          const response = await apiWrapper.createStudentInvitation(username, location.state.course.id, student['First Name'], student['Last Name']);
          if (tableTitles[2].title === 'Email') {
            const sendInvitationResponse = await apiWrapper.sendStudentInvitationEmail(student[tableTitles[2].title].toLowerCase());
          }
        } catch {
          if (tableTitles[2].title === 'Email') {
            const sendInvitationResponse = await apiWrapper.sendStudentInvitationEmail(student.username.toLowerCase());
          }
        }
        setInstructionModalToggled(true);
        setIsConfirmLoading(false);
        history.push('/rostering', { course: location.state.course });
      });
    } else {
      tableInput.forEach(async (student) => {
        if (student.lastName && student.firstName && (tableTitles[2].value === 'username' || student.username)) {
          const username = student.username ? student.username.toLowerCase() : null;
          try {
            const response = await apiWrapper.createStudentInvitation(username, location.state.course.id, student.firstName, student.lastName);
            if (tableTitles[2].title === 'Email') {
              const sendInvitationResponse = await apiWrapper.sendStudentInvitationEmail(student.username.toLowerCase());
            }
          } catch {
            if (tableTitles[2].title === 'Email') {
              const sendInvitationResponse = await apiWrapper.sendStudentInvitationEmail(student.username.toLowerCase());
            }
          }
        }
        setInstructionModalToggled(true);
        setIsConfirmLoading(false);
        history.push('/rostering', { course: location.state.course });
      });
    }
  };

  const renderStudentsTable = () => students.map((student, index) => {
    if (location.state.data !== undefined && location.state.data.length > 0) {
      return (
        <tr key={student.id}>
          <td>{index + 1}</td>
          <td>{student['Last Name']}</td>
          <td>{student['First Name']}</td>
          <td>
            {tableTitles[2].value !== 'username' ? (
              <>{student[tableTitles[2].title]}</>
            ) : (
              <>
                <h7>Unique Username Will Be Generated</h7>
              </>
            )}
          </td>
        </tr>
      );
    } else if (student.lastName && student.firstName && (tableTitles[2].value === 'username' || student.username)) {
      return (
        <tr key={student.id}>
          <td>{index + 1}</td>
          <td>{student.lastName}</td>
          <td>{student.firstName}</td>
          <td>
            {tableTitles[2].value !== 'username' ? (
              <>{student.username}</>
            ) : (
              <>
                <h7>Unique Username Will Be Generated</h7>
              </>
            )}
          </td>
        </tr>
      );
    }
  });

  const renderStudentTableHeader = () => tableTitles.map((item, index) => {
    const isChosen = sortingParams.title === item.value;
    let sortUpColor;
    let sortDownColor;
    if (isChosen) {
      const isDesc = sortingParams.order;
      if (isDesc) {
        sortUpColor = 'rgba(255, 255, 255, 0.4)';
        sortDownColor = 'white';
      } else {
        sortUpColor = 'white';
        sortDownColor = 'rgba(255, 255, 255, 0.4)';
      }
    } else {
      sortUpColor = 'rgba(255, 255, 255, 0.4)';
      sortDownColor = 'rgba(255, 255, 255, 0.4)';
    }
    return (
      <th key={index}>
        <Button
          className="d-flex align-items-center bg-transparent shadow-none border-0"
          size="sm"
        >
          <b>
            {item.title}
          </b>
          <div className="d-flex flex-column align-items-center ml-2">
            <FontAwesomeIcon icon={faSortUp} color={sortUpColor} />
            <FontAwesomeIcon
              icon={faSortDown}
              color={sortDownColor}
              style={{ marginTop: -12 }}
            />
          </div>
        </Button>
      </th>
    );
  });
  return (
    <MainLayout history={history}>
      <Row>
        <Col sm={12} lg={6}>
          <div className="d-flex mt-4">
            <h2>{location.state.course.name}</h2>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>3. Review and Confirm</h5>
        </Col>
      </Row>
      <Table responsive className="mt-4">
        <thead>
          <tr
            className="text-white"
            style={{ backgroundColor: 'hsl(200, 92%, 51%)' }}
          >
            <th />
            {renderStudentTableHeader()}
          </tr>
        </thead>
        <tbody>{renderStudentsTable()}</tbody>
      </Table>
      <h6>
        {reviewAndConfirmMessage[location.state.course.enrollment_type]}
      </h6>
      <Button
        variant="success"
        className="align-self-center submit button-style"
        onClick={createAndSendInvitations}
      >
        {location.state.course.enrollment_type === 'email' ? (
          <>
            Confirm & Send Email Invites &nbsp;
          </>
        ) : (
          <>
            Confirm &nbsp;
          </>
        )}
        {isConfirmLoading
          && (
          <Spinner size="sm" animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
          )}
      </Button>
    </MainLayout>
  );
};

export default ReviewRoster;
