/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react';
import {
  Form, Col, Row, Table, Button, Spinner, InputGroup, FormControl, Toast,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { Link } from 'react-router-dom';
import MainLayout from '../../components/main-layout';
import Paginate from '../../components/paginate';
import './styles.css';
import Modal from '../../components/modal';
import apiWrapper from '../../api';
import { COLUMN_TO_NAME_MAP } from '../../utils/grade-downloader';
import {
  tableTitles, setTableTitles, NO_INVITATIONS_PROMPT, setHeaderLoginMethod, setInstructionModalToggled, instructionModalToggled, selectedCourseIDTeacherInterface, setSelectedCourseIDTeacherInterface,
} from '../../utils/roster';

const Rostering = ({ location, history }) => {
  const [courses, setCourses] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState(0);
  const [selectedCourse, setSelectedCourse] = useState();
  const [students, setStudents] = useState();
  const [totalPages, setTotalPages] = useState();
  const [sortingParams, setSortingParams] = useState({
    title: 'last_name',
    orderDescending: true,
  });
  const [selectedLogin, setLogin] = useState('');
  const [searchRequest, setSearchRequest] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [modalState, setModalState] = useState(false);
  const [studentsLoading, setStudentsLoading] = useState(false);
  const [isDownloadingCSV, setIsDownloadingCSV] = useState(false);
  const [enrollmentType, setEnrollmentType] = useState();
  const [showToast, setShowToast] = useState(false);
  const toggleModal = () => setModalState(!modalState);

  const fetchInvitations = (courseId) => {
    apiWrapper.getAllStudentInvitations(courseId).then((res) => {
      setStudents(res.data);
      setStudentsLoading(false);
    });
  };

  const onAddStudents = () => {
    const today = new Date();
    const courseEndDate = new Date(selectedCourse.end_date);
    if (today <= courseEndDate) {
      history.push('/rostering/add', { selectedCourse });
    } else {
      setShowToast(true);
    }
  };

  const onCourseSelectedFromOverview = (course) => {
    fetchInvitations(course.id);
    setSelectedCourseId(course.id);
    setSelectedCourse(course);
    setTableTitles(course.enrollment_type);
  };

  // DATA FETCHING
  useEffect(() => {
    apiWrapper.getCourses().then((res) => {
      setCourses(res.data);
      if (res.data.length === 1) {
        setSelectedCourseId(res.data[0].id);
        setSelectedCourse(res.data[0]);
        fetchInvitations(res.data[0].id);
      }
      if (selectedCourseIDTeacherInterface !== 0) {
        setStudentsLoading(true);
        setSelectedCourseId(selectedCourseIDTeacherInterface);
        setSearchRequest('');
        const courseFound = res.data.find(
          (course) => course.id === Number(selectedCourseIDTeacherInterface),
        );
        setSelectedCourse(courseFound);
        setEnrollmentType(courseFound.enrollment_type);
        const courseId = courseFound ? courseFound.id : null;
        if (courseFound.enrollment_type !== 'self') fetchInvitations(courseId);
        else getAllCourseStudents(courseId);
        setTableTitles(courseFound.enrollment_type);
      }
    });
    if (location.state !== undefined && location.state) {
      onCourseSelectedFromOverview(location.state.course);
      if (instructionModalToggled) {
        toggleModal();
        setInstructionModalToggled(false);
      }
    }
  }, []);

  const getEnvironmentURL = () => {
    const currentURL = window.location.href;
    let environment = '';
    if (currentURL.indexOf('3000') >= 0) environment = 'local';
    if (currentURL.indexOf('com') >= 0) environment = 'dev';
    if (currentURL.indexOf('education') >= 0) environment = 'prod';
    switch (environment) {
      case 'prod':
        return process.env.REACT_APP_ROOT_PROD_URL;
      case 'dev':
        return process.env.REACT_APP_ROOT_DEV_URL;
      case 'local':
        return process.env.REACT_APP_ROOT_LOCAL_URL;
      default:
        return process.env.REACT_APP_ROOT_PROD_URL;
    }
  };

  const renderCourses = () => courses.map((course) => (
    <option key={course.id} value={course.id}>
      {course.name}
    </option>
  ));

  const getAllCourseStudents = async (courseId) => {
    const res = await apiWrapper.getCourseStudents(courseId, 1);
    const data = res.data.records;
    const totalPages = res.data.pagination.total_page;
    if (totalPages > 1) {
      for (let page = 2; page <= totalPages; page += 1) {
        const response = await apiWrapper.getCourseStudents(courseId, page);
        data.push(...response.data.records);
      }
    }
    setStudents([...data]);
    setStudentsLoading(false);
  };

  // COURSE SELECTION
  const onCourseChangeId = (e) => {
    setStudentsLoading(true);
    setSelectedCourseId(e.target.value);
    setSelectedCourseIDTeacherInterface(e.target.value);
    setSearchRequest('');
    const courseFound = courses.find(
      (course) => course.id === Number(e.target.value),
    );
    setSelectedCourse(courseFound);
    setEnrollmentType(courseFound.enrollment_type);
    const courseId = courseFound ? courseFound.id : null;
    if (courseFound.enrollment_type !== 'self') fetchInvitations(courseId);
    else getAllCourseStudents(courseId);
    setTableTitles(courseFound.enrollment_type);
  };

  const changePageFunction = (number) => {
    const sortingOrderName = sortingParams.order ? 'desc' : 'asc';
    const courseId = selectedCourse ? selectedCourse.id : null;
    const query = searchRequest || null;
    fetchInvitations(courseId);
  };

  const onChangeSearchRequest = (e) => {
    setStudentsLoading(true);
    if (typingTimeout) clearTimeout(typingTimeout);
    setSearchRequest(e.target.value);
    const query = e.target.value;
    const sortingOrderName = sortingParams.orderDescending ? 'desc' : 'asc';
    setTypingTimeout(
      setTimeout(() => {
        const courseId = selectedCourse ? selectedCourseId : null;
        if (query) {
          apiWrapper.searchInvitations(courseId, query).then((res) => {
            setStudents(res.data);
            setStudentsLoading(false);
          });
        } else {
          if (enrollmentType !== 'self') fetchInvitations(courseId);
          else getAllCourseStudents(courseId);
          setStudentsLoading(false);
        }
      }, 800),
    );
  };

  const onChangeRadioButton = (e) => {
    setLogin(e.target.value);
  };

  const saveRadioButtonSelected = () => {
    const newEnrollmenType = setHeaderLoginMethod(selectedLogin);
    selectedCourse.enrollment_type = newEnrollmenType;
    apiWrapper.updateCourseEnrollmentType(selectedCourse.id, newEnrollmenType).then((res) => {
      console.log(res.data);
    });
    history.push('/rostering/add', { selectedCourse });
  };

  const onSort = (sortedBy) => {
    const sortingOrder = sortingParams.title === sortedBy ? !sortingParams.order : false;
    setSortingParams({
      title: sortedBy,
      order: sortingOrder,
    });
    const sortingOrderName = sortingOrder ? 'desc' : 'asc';
    const courseId = selectedCourse ? selectedCourse.id : null;
    const query = searchRequest || null;
    fetchInvitations(courseId);
  };

  const toCSV = (json) => {
    let csv = '';
    // CSV Header
    const keys = (json[0] && Object.keys(json[0])) || [];
    csv += `${keys.map((key) => COLUMN_TO_NAME_MAP[key].label).join(',')}\n`;

    // CSV Data
    json.forEach((line) => {
      csv += `${keys.map((key) => (key in COLUMN_TO_NAME_MAP) ? line[key] : '').join(',')}\n`;
    });

    return csv;
  };

  const transformDataToFormat = () => {
    let json = [];
    students.forEach((student) => {
      json.push({ first_name: student.first_name, last_name: student.last_name, username: student.username ? student.username : student.email });
    });
    return json;
  };

  const downloadAsCSV = () => {
    setIsDownloadingCSV(true);
    const csv = toCSV(transformDataToFormat(students));
    const encodedUri = window.encodeURI(`data:text/csv;charset=utf-8,${csv}`);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `roster_${selectedCourse.name}_${moment().format()}.csv`);
    document.body.appendChild(link); // Required for FF

    link.click();
    setIsDownloadingCSV(false);
  };

  const openResetPasswordTab = (e) => {
    e.preventDefault();
    const index = e.target.id.split('_')[1];
    const win = students[index].username ? window.open(`/reset-password/${students[index].username}/${students[index].user_id}`, '_blank', 'noopener')
      : window.open(`/reset-password/${students[index].email}/${students[index].user_id}`, '_blank', 'noopener');
  };

  const renderStudentTableHeader = () => tableTitles.map((item, index) => {
    const isChosen = sortingParams.title === item.value;

    let sortUpColor;
    let sortDownColor;
    if (isChosen) {
      const isDesc = sortingParams.order;
      if (isDesc) {
        sortUpColor = 'rgba(255, 255, 255, 0.4)';
        sortDownColor = 'white';
      } else {
        sortUpColor = 'white';
        sortDownColor = 'rgba(255, 255, 255, 0.4)';
      }
    } else {
      sortUpColor = 'rgba(255, 255, 255, 0.4)';
      sortDownColor = 'rgba(255, 255, 255, 0.4)';
    }
    return (
      <th key={index}>
        <Button
          className="d-flex align-items-center bg-transparent shadow-none border-0"
          size="sm"
          onClick={() => {
            onSort(item.value);
          }}
        >
          <b>
            {item.title}
          </b>
          <div className="d-flex flex-column align-items-center ml-2">
            <FontAwesomeIcon icon={faSortUp} color={sortUpColor} />
            <FontAwesomeIcon
              icon={faSortDown}
              color={sortDownColor}
              style={{ marginTop: -12 }}
            />
          </div>
        </Button>
      </th>
    );
  });
  const renderStudentsTable = () => students.map((student, index) => (
    <tr>
      <td>{index + 1}</td>
      <td>{student.last_name}</td>
      <td>{student.first_name}</td>
      <td>
        {student.username ? student.username : student.email}
        {!student.user_id && selectedCourse.enrollment_type.split('_')[0] !== 'sso' && selectedCourse.enrollment_type !== 'self' ? (
          <>
            <div className="student-inactive">needs to activate</div>
          </>
        ) : (
          <>
            {selectedCourse.enrollment_type === 'username' && (
              <>
                <Link
                  id={`status_${index}`}
                  className="student-active"
                  onClick={openResetPasswordTab}
                >
                  reset password
                </Link>
              </>
            )}
          </>
        )}
      </td>
    </tr>
  ));

  const renderSelfEnrollmentInstructions = () => {
    return (
      <div>
        <Button
          variant="success"
          className="submit button-style roster-button"
          onClick={toggleModal}
        >
          Student Enrollment & Activation Instructions
        </Button>
        <Modal show={modalState} handleClose={toggleModal} close="button">
          <h2 className="csv-modal-title">{`${selectedCourse.name} Student Enrollment & Activation Instructions`}</h2>
          <h6>Provide your students with the following Spatial Vis enrollment instructions:</h6>
          <ol>
            <li><h6>Visit the eGrove Education website: <a target="_blank" href="https://egrove.education/" rel="noopener noreferrer" className="link">https://egrove.education/</a></h6></li>
            <li><h6>Click the "Enroll/Sign Up" button in the upper right corner.</h6></li>
            <li><h6>Click "Sign Up"</h6></li>
            <li><h6>Create an account by entering your First Name, Last Name, Email Address, and Password. Then click “Sign up”.</h6></li>
            <li><h6>Verify your email address by logging into your account email. You should have a “Welcome to Spatial Vis!” email. Open the email and select the green “Confirm your account" button. If the button does not appear, copy and paste the URL at the bottom of your email.</h6></li>
            <li><h6>You will be redirected to log in to enroll in a course. Log in using your Email and Password from your new account and click submit. If you are not redirected to log in, visit the eGrove Education website (<a target="_blank" href="https://egrove.education/" rel="noopener noreferrer" className="link">https://egrove.education/</a>) and click the “Enroll/Sign Up” button in the upper right corner. Log in to your account.</h6></li>
            <li><h6><p>Enter the following course invite code: <b>{selectedCourse.code}</b></p></h6></li>
            <li><h6>If the course looks correct, proceed to the payment screen by selecting “Checkout”.</h6></li>
            <li><h6>Enter your credit card information and select pay. Now you are enrolled in the course.</h6></li>
            <li><h6>You may now log in to the Spatial Vis mobile app on your Chromebook, Tablet, or Phone using the Apple App or Google Play app. You may also log in from your computer by logging in on our website at <a target="_blank" href="https://egrove.education/" rel="noopener noreferrer" className="link">https://egrove.education/</a>.</h6></li>
          </ol>
          <h6>Contact info@egrove.education if you have any questions.</h6>
        </Modal>
      </div>
    );
  };

  return (
    <MainLayout history={history}>
      {students && students.length ? (
        <>
          <Row>
            <Col sm={12} lg={6}>
              <div className="d-flex mt-4">
                <h2>Course</h2>
                <Form
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flex: 1,
                    marginLeft: 40,
                  }}
                >
                  <Form.Control
                    as="select"
                    name="course"
                    value={selectedCourseId}
                    onChange={onCourseChangeId}
                  >
                    <option disabled value={0}>
                      Select option
                    </option>
                    {renderCourses()}
                  </Form.Control>
                </Form>
              </div>
            </Col>
            <Col sm={12} lg={6} className="mt-4">
              <Button
                variant="success"
                className="submit button-style add-button"
                onClick={toggleModal}
              >
                Student Enrollment & Activation Instructions
              </Button>
            </Col>
            <Modal show={modalState} handleClose={toggleModal} close="button">
              <div className="csv-modal-contents">
                <h2 className="csv-modal-title">{`${selectedCourse.name} Student Enrollment & Activation Instructions`}</h2>
                {selectedCourse.enrollment_type.split('_')[0] === 'sso' ? (
                  <>
                    <h6>Sign on to the Spatial Vis app through the Schoology portal.</h6>
                  </>
                ) : (
                  <>
                    {selectedCourse.enrollment_type === 'email' && (
                      <>
                        <h6>
                          Your students have received an email invite to set a password for their account. After they set their password, they will be able to login to Spatial Vis.
                          Below are the student account activation and enrollment instructions. Additionally, we have provided alternative instructions if they cannot access the email invitation.
                        </h6>
                          <h6><b>Student Account Activation and Enrollment Instructions:</b></h6>
                          <ol>
                            <li><h6>Check your email for a Spatial Vis invitation with the following subject line: "Spatial Vis account creation"</h6></li>
                            <li><h6>Accept your invite and set your account password by selecting the "Accept Invite and Set Password" button. If the button does not appear, copy and paste the URL at the bottom of your email.</h6></li>
                            <li><h6>Create your password.</h6></li>
                            <li><h6>Confirm your password by entering it a second time.</h6></li>
                            <li><h6> You may now log in to the Spatial Vis mobile app on your Chromebook, Tablet, or Phone using the Apple App or Google Play app. You may also log in from your computer by logging in on our website at <a target="_blank" href="https://egrove.education/" rel="noopener noreferrer" className="link">https://egrove.education/</a>.</h6></li>
                          </ol>
                          <h6>Note: If you cannot find the email invitation, please use the Alternative Student Account Activation and Enrollment Instructions below. If you have any questions, contact info@egrove.education.</h6>
                          <h6><b>Alternative Student Account Activation and Enrollment Instructions (if student did not receive the email invitation):</b></h6>
                        </>
                      )}
                      {selectedCourse.enrollment_type === 'username' && (
                        <><h6>Provide each student with their unique username and the instructions below:</h6></>
                      )}
                      {selectedCourse.enrollment_type !== 'self' && (
                        <><ol>
                          <li><h6>Visit <a target="_blank" rel="noopener noreferrer" href={getEnvironmentURL() + '/invitation'} className="link">{getEnvironmentURL() + '/invitation'}</a> in your web browser.</h6></li>
                          <li><h6><p>Enter the following course invite code: <b>{selectedCourse.code}</b></p></h6></li>
                          <li>
                            {selectedCourse.enrollment_type === 'username' ? (
                              <><h6>Enter your unique username provided by your instructor.</h6></>
                            ) : (
                                <><h6>Enter your email address as your username.</h6></>
                              )}
                          </li>
                          <li><h6>Create your password.</h6></li>
                          <li><h6>Confirm your password by entering it a second time.</h6></li>
                          <li><h6>You may now log in to the Spatial Vis mobile app on your Chromebook, Tablet, or Phone using the Apple App or Google Play app. You may also log in from your computer by logging in on our website at <a target="_blank" href="https://egrove.education/" rel="noopener noreferrer" className="link">https://egrove.education/</a>.</h6></li>
                        </ol>
                          <h6>Contact info@egrove.education if you have any questions.</h6></>
                      )}
                    </>
                  )}
                {students && selectedCourse && selectedCourse.enrollment_type === 'self' && (
                  <>
                    {renderSelfEnrollmentInstructions()}
                  </>
                )}
              </div>
            </Modal>
          </Row>
        </>
      ) :
        <>
          <Row>
            <Col sm={12} lg={6}>
              <div className="d-flex mt-4">
                <h2>Course</h2>
                <Form
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flex: 1,
                    marginLeft: 40,
                  }}
                >
                  <Form.Control
                    as="select"
                    name="course"
                    value={selectedCourseId}
                    onChange={onCourseChangeId}
                  >
                    <option disabled value={0}>
                      Select option
                            </option>
                    {renderCourses()}
                  </Form.Control>
                </Form>
              </div>
            </Col>
          </Row>
          <div>
            {selectedCourse && selectedCourse.enrollment_type !== 'self' && selectedCourse.enrollment_type !== 'tbd' && !searchRequest && (
              <>
                <Button
                  variant="success"
                  className="align-self-center submit button-style add-button"
                  onClick={onAddStudents}
                >
                  Add Students
                  </Button>
              </>
            )}
          </div>
        </>
      }
      {students &&
        <Row className="mt-5">
          <Col>
            <div className="d-flex">
              <h2>Students</h2>
              <InputGroup className="ml-4">
                <FormControl
                  placeholder="Find a student"
                  aria-label="Find a student"
                  value={searchRequest}
                  onChange={onChangeSearchRequest}
                />
              </InputGroup>
            </div>
          </Col>
        </Row>
      }
      <Row>
        <Col>
          <div>
            <Toast className="toast-message" variant="primary" onClose={() => setShowToast(false)} show={showToast}>
              <Toast.Header style={{ backgroundColor: 'rgb(15, 168, 245)', color: 'white' }}>
                <strong className="me-auto">This course is inactive. Contact info@egrove.education if you believe this is an issue.</strong>
              </Toast.Header>
            </Toast>
          </div>
        </Col>
      </Row>
      {students && students.length || searchRequest ? (
        <>{studentsLoading ? (
          <div className="students-spinner">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>) : (
            <>
              <Row className="mt-3">
                {selectedCourse.enrollment_type !== 'self' ? (
                  <><Col>
                    <Button
                      variant="success"
                      className="align-self-center button-style add-button"
                      onClick={downloadAsCSV}
                    >
                      Download Roster &nbsp;
                      {isDownloadingCSV &&
                        <Spinner size="sm" animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      }
                    </Button>
                  </Col>
                  <Col>
                      <Button
                        variant="success"
                        className="align-self-center button-style add-button"
                        onClick={onAddStudents}
                      >
                        Add Students
                      </Button>
                  </Col></>
                ) : (
                    <><Button
                      variant="success"
                      className="align-self-center button-style add-button"
                      onClick={downloadAsCSV}
                    >
                      Download Roster &nbsp;
                      {isDownloadingCSV &&
                        <Spinner size="sm" animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      }
                    </Button></>
                  )}
              </Row>
              <Row>
                <Col>
                  <Table responsive className="mt-4">
                    <thead>
                      <tr
                        className="text-white"
                        style={{ backgroundColor: 'hsl(200, 92%, 51%)' }}
                      >
                        <th />
                        {renderStudentTableHeader()}
                      </tr>
                    </thead>
                    <tbody>{renderStudentsTable()}</tbody>
                  </Table>
                  {totalPages && (
                    <Paginate totalPages={totalPages} changePageFunction={changePageFunction} />
                  )}
                </Col>
              </Row></>)}</>
      ) :
        (<>
          {selectedCourse && selectedCourse !== undefined && selectedCourse !== '' && selectedCourse.enrollment_type === 'tbd' && selectedCourse.is_paid ? (
            <>
              <div>
                <h5>{NO_INVITATIONS_PROMPT.question}</h5>
                {NO_INVITATIONS_PROMPT.choices.map((option, index) => {
                  return (
                    <div>
                      <input
                        key={index}
                        type="radio"
                        value={option}
                        defaultChecked={false}
                        onChange={onChangeRadioButton}
                        checked={selectedLogin === option}
                      />
                      <span>{option}</span>
                    </div>
                  );
                })}
                <Button
                  variant="success"
                  className="align-self-center submit button-style"
                  onClick={saveRadioButtonSelected}
                >
                  Save & Next
                </Button>
              </div>
            </>
          ) : (
              <>
                {students && !students.length && selectedCourse && selectedCourse.enrollment_type === 'self' && (
                  <>
                    <h6 className="self-enrollment-text">No students have enrolled in your course. Please provide your students with the student enrollment instructions below.</h6>
                    {renderSelfEnrollmentInstructions()}
                  </>
                )}
              </>
            )}
        </>
        )}
    </MainLayout>
  );
};

export default Rostering;
