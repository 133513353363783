/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useContext } from 'react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { Button, Spinner } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StarIcon from '@material-ui/icons/Star';
import { faCrown } from '@fortawesome/free-solid-svg-icons';
import { isMobileOnly, isBrowser } from 'react-device-detect';
import WebAppLayout from '../../components/web-app-layout';
import './styles.css';
import { webAppContext } from '../../contexts/web-app-context';
import AppStoreDownload from '../../resources/images/downloadOn_appStore.png';
import PlayStoreDownload from '../../resources/images/downloadOn_playStore.png';
import WebAppPopUp from '../../components/web-app-pop-up';

const SpatialVisWeb = ({ location, history }) => {
  const [isHeightToBeSet, setIsHeightToBeSet] = useState(false);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [modalState, setModalState] = useState(true);
  const [lessonStars, setLessonStars] = useState([]);
  const {
    lessonTotals, assignments, syllabus, percentages, stars, dataLoading, onLessonOpen, submissions, lessonAssignments, userActiveEnrollment,
  } = useContext(webAppContext);

  const toggleModal = () => {
    setModalState(!modalState);
  };

  const debounce = (fn, ms) => {
    let timer;
    return () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        // eslint-disable-next-line prefer-rest-params
        fn.apply(this, [fn, ms]);
      }, ms);
    };
  };

  const updateIsHeightToBeSet = () => {
    if (window.innerWidth < 1200 && window.innerHeight < 794) setIsHeightToBeSet(false);
    else if (window.innerWidth > 1200 && window.innerHeight < 548) setIsHeightToBeSet(false);
    else if (window.innerWidth > 1200 && window.innerHeight > 548) setIsHeightToBeSet(true);
    else if (window.innerWidth < 1200 && window.innerHeight > 548) setIsHeightToBeSet(false);
  };

  useEffect(() => {
    if (!dataLoading && !userActiveEnrollment) history.replace('/');
  }, [dataLoading, userActiveEnrollment, history]);

  useEffect(() => {
    getStarsEarnedPerLesson();
  }, [submissions]);

  useEffect(() => {
    updateIsHeightToBeSet();
  }, [screenHeight, screenWidth, isHeightToBeSet]);

  const navigateToLesson = (lesson, index) => {
    onLessonOpen();
    document.title = lesson.name;
    history.push(`/spatial-vis-web/${lesson.name}`, {
      lesson,
      index,
      lessonTotal: lessonTotals[lesson.code],
      assignments,
      userData: location.state?.userData,
    });
  };

  const calculateStarsPerLesson = (submissionsByLesson) => {
    const starsPerLesson = {
      intro: 0,
      rot: 0,
      iso: 0,
      ortho: 0,
      _2d3d: 0,
      slopes: 0,
      flat: 0,
      rot1: 0,
      rot2: 0,
      assembly: 0,
    };
    for (const lesson in submissionsByLesson) {
      let starCount = 0;
      submissionsByLesson[lesson].forEach((submission) => {
        if (!submission.is_correct) starCount += 0;
        else if (submission.is_correct) {
          if (submission.did_peek) starCount += 1;
          else if (submission.did_look_at_hint) starCount += 2;
          else starCount += 3;
        }
      });
      starsPerLesson[lesson] = starCount;
    }
    return starsPerLesson;
  };

  const getStarsEarnedPerLesson = () => {
    const enrollmentID = submissions[0]?.enrollment_id;
    const currentSubmissions = submissions?.filter((sub) => sub.enrollment_id === enrollmentID);
    const submissionsByLesson = {
      intro: [],
      rot: [],
      iso: [],
      ortho: [],
      '2D3D': [],
      slopes: [],
      flat: [],
      rot1: [],
      rot2: [],
      assembly: [],
    };
    currentSubmissions.forEach((sub) => {
      const currentLesson = lessonAssignments[sub.lesson_assignment_id - 1];
      if (currentLesson) submissionsByLesson[currentLesson?.lesson_code].push(sub);
    });
    setLessonStars(calculateStarsPerLesson(submissionsByLesson));
  };

  const renderLessons = (lessonPercentages) => (
    syllabus.map((lesson, index) => (
      <button key={lesson.display_order} type="button" className="dashboard-items" onClick={() => { navigateToLesson(lesson, index); }}>
        { lessonPercentages[lesson.code] < 100
          ? (
            <CircularProgressbarWithChildren
              className="ciruclar-progress-bar"
              value={lessonPercentages[lesson.code]}
              styles={buildStyles({
                pathColor: '#6abd32',
                trailColor: '#b8b8b8',
              })}
            >
              <div className="progress-bar-inner-text">
                {lessonPercentages[lesson.code]}
                %
              </div>
              <div style={{ display: 'inline-flex', alignItems: 'baseline' }}>
                <StarIcon fontSize="large" style={{ color: '#f7b727', alignSelf: 'center' }} />
                <div className="progress-bar-inner-text">{lessonStars[lesson.code]}</div>
              </div>
            </CircularProgressbarWithChildren>
          )
          : (
            <CircularProgressbarWithChildren
              className="ciruclar-progress-bar"
              styles={buildStyles({
                textSize: '16px',
                pathColor: '#f2b940',
                textColor: '#e4f5ff',
                trailColor: '#f2b940',
              })}
            >
              <div style={{ position: 'absolute' }}><FontAwesomeIcon size="7x" icon={faCrown} color="#f2b940" /></div>
              <div style={{
                display: 'inline-flex', alignItems: 'baseline', position: 'absolute', paddingTop: '25px',
              }}
              >
                <StarIcon fontSize="large" style={{ color: 'black', alignSelf: 'center' }} />
                <div className="progress-bar-completed-text">{lessonStars[lesson.code]}</div>
              </div>
            </CircularProgressbarWithChildren>
          )}
        <h2 className="text">{(index === 0 ? `${lesson.name}` : `${index}. ${lesson.name}`)}</h2>
      </button>
    ))
  );

  return (
    <div style={{ height: isHeightToBeSet && '100vh', backgroundColor: '#e4f5ff' }}>
      <WebAppLayout stars={stars} history={history}>
        { dataLoading
          ? (
            <div style={{
              display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center',
            }}
            >
              <Spinner style={{ color: '#0f6492' }} animation="grow" />
            </div>
          )
          : (
            <>
              <div className="dashboard">{renderLessons(percentages)}</div>
              <WebAppPopUp show={modalState && isBrowser && stars === 0 && submissions.length === 0} handleClose={toggleModal} close="" isMobile={!isBrowser}>
                <div style={{ textAlign: 'center', justifyContent: 'center' }}>
                  <h3><b>Your account is now active!</b></h3>
                  <h3 style={{ marginTop: '20px' }}>You may complete your assignments on the web or on one of our mobile apps.</h3>
                  <div style={{ display: 'inline' }}>
                    <Button className="mobile-app-link" onClick={() => { window.open('https://apps.apple.com/us/app/spatial-vis/id1495249170', '_blank', 'noopener noreferrer'); }}>
                      <img src={AppStoreDownload} width="312" height="102" alt="AppStore" />
                    </Button>
                    <Button className="mobile-app-link" onClick={() => { window.open('https://play.google.com/store/apps/details?hl=en_US&id=education.egrove.spatialvis', '_blank', 'noopener noreferrer'); }}>
                      <img src={PlayStoreDownload} width="312" height="102" alt="PlayStore" />
                    </Button>
                  </div>
                </div>
              </WebAppPopUp>
              <WebAppPopUp show={isMobileOnly && (stars > 0 || submissions.length > 0)} handleClose={toggleModal} close="loading" isMobile={isMobileOnly} isNewAccount={false}>
                <div style={{ textAlign: 'center', justifyContent: 'center' }}>
                  <h3 style={{ marginTop: '20px' }}>It looks like you are on a mobile device. Please use the mobile app on your mobile device.</h3>
                  <Button className="mobile-app-link" onClick={() => { window.open('https://apps.apple.com/us/app/spatial-vis/id1495249170', '_blank', 'noopener noreferrer'); }}>
                    <img src={AppStoreDownload} width="312" height="102" alt="AppStore" />
                  </Button>
                  <Button className="mobile-app-link" onClick={() => { window.open('https://play.google.com/store/apps/details?hl=en_US&id=education.egrove.spatialvis', '_blank', 'noopener noreferrer'); }}>
                    <img src={PlayStoreDownload} width="312" height="102" alt="PlayStore" />
                  </Button>
                </div>
              </WebAppPopUp>
              <WebAppPopUp show={isMobileOnly && stars === 0 && submissions.length === 0} handleClose={toggleModal} close="loading" isMobile={isMobileOnly} isNewAccount={stars === 0 && submissions.length === 0}>
                <div style={{ textAlign: 'center', justifyContent: 'center' }}>
                  <h3><b>Your account is now active!</b></h3>
                  <h3 style={{ marginTop: '20px' }}>You may complete your assignments on a computer by logging in on our website or you may use one of our mobile apps below.</h3>
                  <Button className="mobile-app-link" onClick={() => { window.open('https://apps.apple.com/us/app/spatial-vis/id1495249170', '_blank', 'noopener noreferrer'); }}>
                    <img src={AppStoreDownload} width="312" height="102" alt="AppStore" />
                  </Button>
                  <Button className="mobile-app-link" onClick={() => { window.open('https://play.google.com/store/apps/details?hl=en_US&id=education.egrove.spatialvis', '_blank', 'noopener noreferrer'); }}>
                    <img src={PlayStoreDownload} width="312" height="102" alt="PlayStore" />
                  </Button>
                </div>
              </WebAppPopUp>
            </>
          )}
      </WebAppLayout>
    </div>
  );
};

export default SpatialVisWeb;
