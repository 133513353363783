import React, { Suspense, useContext } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import LogIn from './scenes/login';
import SingleSignOn from './scenes/login/single-sign-on';
import Courses from './scenes/courses';
import Schools from './scenes/schools';
import NewSchool from './scenes/schools/new-school';
import 'bootstrap/dist/css/bootstrap.min.css';
import TeacherOnboarding from './scenes/onboarding/teacher';
import PrivateRoute from './components/private-route';
import EditSchool from './scenes/schools/edit-school';
import NewCourse from './scenes/courses/new-course';
import EditCourse from './scenes/courses/edit-course';
import Users from './scenes/users';
import NewTeacher from './scenes/users/new-teacher';
import ViewCourse from './scenes/courses/view-course';
import StarsGradings from './scenes/gradings/stars';
import PercentsGradings from './scenes/gradings/percents';
import { authContext } from './contexts/auth-context';
import CourseSelection from './scenes/payment/course-selection';
import CouponForm from './scenes/payment/coupon-form';
import NewCoupon from './scenes/payment/new-coupon';
import Payment from './scenes/payment';
import ResetPasswordRequest from './scenes/reset-password/request';
import ResetPasswordScene from './scenes/reset-password/reset';
import AccountActivation from './scenes/users/activation';
import SignUp from './scenes/sign-up';
import Overview from './scenes/overview';
import AssignmentSets from './scenes/assignment-sets';
import AssignmentSetForm from './scenes/assignment-sets/assignment-set-form';
import Rostering from './scenes/rostering';
import AddStudents from './scenes/rostering/add-students';
import ReviewRoster from './scenes/rostering/review-and-confirm';
import UserManagement from './scenes/user-management';
import Invitation from './scenes/invitation';
import InvitationUsername from './scenes/invitation/username';
import InvitationPassword from './scenes/invitation/password';
import UsernameResetPassword from './scenes/reset-password/username';
import SpatialVisWeb from './scenes/spatial-vis-web';
import SpatialVisWebLesson from './scenes/spatial-vis-web/lesson';

function App() {
  const { auth } = useContext(authContext);

  let mainComponent = Courses;
  if (auth && auth?.user?.account_level === 'student') {
    mainComponent = CourseSelection;
  }
  if (auth && auth?.user?.account_level === 'teacher') {
    mainComponent = Overview;
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Route exact path="/sign-in" component={LogIn} />
        <Route exact path="/sign-up" component={SignUp} />
        <Route exact path="/invite/:id/:key" component={TeacherOnboarding} />
        <Route exact path="/reset/:id/:key" component={ResetPasswordScene} />
        <Route exact path="/activate/:id/:key" component={AccountActivation} />
        <Route exact path="/sign-in/forgotten" component={ResetPasswordRequest} />
        <PrivateRoute exact path="/" component={mainComponent} />
        <PrivateRoute exact path="/overview" component={Overview} />
        <PrivateRoute exact path="/assignment-sets" component={AssignmentSets} />
        <PrivateRoute exact path="/assignment-sets/create" component={AssignmentSetForm} />
        <PrivateRoute exact path="/assignment-sets/edit" component={AssignmentSetForm} />
        <PrivateRoute exact path="/rostering" component={Rostering} />
        <PrivateRoute exact path="/rostering/add" component={AddStudents} />
        <PrivateRoute exact path="/rostering/review" component={ReviewRoster} />
        <PrivateRoute exact path="/payment/:code" component={Payment} />
        <PrivateRoute exact path="/coupons" component={CouponForm} />
        <PrivateRoute exact path="/coupons/new-coupon" component={NewCoupon} />
        <PrivateRoute exact path="/courses/new" component={NewCourse} />
        <PrivateRoute exact path="/courses/edit" component={EditCourse} />
        <PrivateRoute exact path="/courses/:id/show" component={ViewCourse} />
        <PrivateRoute exact path="/courses/:id/stars" component={StarsGradings} />
        <PrivateRoute exact path="/courses/:id/percents" component={PercentsGradings} />
        <PrivateRoute exact path="/schools" component={Schools} />
        <PrivateRoute exact path="/schools/new" component={NewSchool} />
        <PrivateRoute exact path="/schools/edit" component={EditSchool} />
        <PrivateRoute exact path="/users" component={Users} />
        <PrivateRoute exact path="/user-management" component={UserManagement} />
        <PrivateRoute exact path="/users/new-teacher" component={NewTeacher} />
        <Route exact path="/invitation" component={Invitation} />
        <Route exact path="/invitation/username" component={InvitationUsername} />
        <Route exact path="/invitation/:username/password" component={InvitationPassword} />
        <PrivateRoute exact path="/reset-password/:username/:id" component={UsernameResetPassword} />
        <PrivateRoute exact path="/spatial-vis-web" component={SpatialVisWeb} />
        <PrivateRoute exact path="/spatial-vis-web/:lesson" component={SpatialVisWebLesson} />
        <Route exact path="/saml_signin/:token" component={SingleSignOn} />
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
