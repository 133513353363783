import React, { useEffect, useState } from 'react';
import { csv } from 'd3';
import './styles.css';
import { tableTitles, setData } from '../../utils/roster';
import Modal from '../../components/modal';
import { Button } from 'react-bootstrap';
import moment from 'moment';

let error = '';
let validFile = true;

const CSVUploader = ({ history }) => {
    const [fileName, setFileName] = useState('');
    const [modalState, setModalState] = useState(false);
    const enrollment_type = history.location.state.selectedCourse.enrollment_type;
    const tableHeader = tableTitles[2].title;
    useEffect(() => {
        setData([]);
    }, []);

    const toggleModal = () => {
        setFileName('');
        setModalState(!modalState);
    }

    const downloadAsCSV = () => {
        const csv = enrollment_type !== 'username' ? `Last Name,First Name,${tableHeader}` : 'Last Name,First Name';
        const encodedUri = window.encodeURI(`data:text/csv;charset=utf-8,${csv}`);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `CSV_Template_${moment().format()}.csv`);
        document.body.appendChild(link); // Required for FF

        link.click();
    };

    const removeKeySpacesAndEmptyRows = (csv) => {
        let emptyRows = [];
        let removeCount = 0;
        csv.forEach((entry, index) => {
            for (const [key, value] of Object.entries(entry)) {
                let expectedKey = key.replace(/\s+$/g, '');
                expectedKey = key.replace(/^\s+/g, '');
                if (key !== expectedKey) {
                    csv[index][expectedKey] = value;
                    delete csv[index][key];
                }
            }
            if (entry["Last Name"] === "" && entry["First Name"] === "" && (entry[tableHeader] === "" || enrollment_type === 'username')) {
                emptyRows.push(index);
            }
        });
        emptyRows.forEach(item => {
            csv.splice(item - removeCount, 1);
            removeCount++;
        });
    }

    const verifyCsvUpload = (csv) => {
        const hasLastName = csv.columns.some(item => item === "Last Name"), hasFirstName = csv.columns.some(item => item === "First Name"), hasEnrollmentType = csv.columns.some(item => item === tableHeader);
        if (enrollment_type !== 'username' && (!hasLastName || !hasFirstName || !hasEnrollmentType)) {
            error = `Error: The column headers must be Last Name, First Name, and ${tableHeader}.`;
            validFile = false;
        } else if (enrollment_type === 'username' && (!hasLastName || !hasFirstName)) {
            error = `Error: The column headers must be Last Name and First Name.`;
            validFile = false;
        } else {
            csv.forEach(entry => {
                for (const [key, value] of Object.entries(entry)) {
                    if (enrollment_type === 'username' && (key === 'First Name' || key === 'Last Name') && value.length === 0) {
                        error = `Error: An entry in the ${key} column in the csv is empty. Please fill it in and reupload.`;
                        validFile = false;
                        break;
                    } else if ((key === 'First Name' || key === 'Last Name' || key === tableHeader) && value.length === 0) {
                        error = `Error: An entry in the ${key} column in the csv is empty. Please fill it in and reupload.`;
                        validFile = false;
                        break;
                    }
                }
            });
        }
    }

    const onChange = (e) => {
        validFile = true;
        setFileName('');
        const files = e.target.files;
        let reader = new FileReader();
        if (files.length) {
            reader.readAsDataURL(files[0]);
            reader.onload = (e) => {
                setFileName(files[0].name);
                csv(e.target.result).then(res => {
                    removeKeySpacesAndEmptyRows(res);
                    verifyCsvUpload(res);
                    if (validFile && files[0].name.indexOf('.csv') >= 0) {
                        let table = [];
                        res.forEach(entry => {
                            let row = enrollment_type !== 'username' ? { "Last Name": entry["Last Name"], "First Name": entry["First Name"], [tableHeader]: entry[tableHeader] }
                                : { "Last Name": entry["Last Name"], "First Name": entry["First Name"] };
                            table.push(row);
                        });
                        setData(table);
                    } else if (files[0].name.indexOf('.csv') < 0) {
                        error = 'Error: Please upload a csv file.';
                        validFile = false;
                    }
                });
            }
        }
    }

    const determineInputDisplay = () => {
        if (fileName === '' || fileName === 'Error: Please select a CSV to upload.') setFileName('Error: Please select a CSV to upload.')
        else if (!validFile) setFileName(error);
        else {
            toggleModal();
            history.push('/rostering/add', { selectedCourse: history.location.state.selectedCourse });
        }
    }

    const resetValue = (e) => {
        e.target.value = '';
    }

    const renderFileSelect = () => {
        return (<>
            <label htmlFor="file-upload" className="custom-file-upload button-style">
                <i className="fa fa-cloud-upload"></i> Choose File
            </label>
            <input id="file-upload" type="file" name="file" onClick={(e) => { resetValue(e) }} onChange={(e) => onChange(e)} />
            <input className={fileName.indexOf('.csv') >= 0 ? 'input-file' : 'input-error'} disabled={true} value={fileName}></input>
        </>);
    }
    return (
        <div>
            <Button variant="success" className="align-self-center csv-import button-style" onClick={toggleModal}>
                Import Roster From a CSV
            </Button>
            <div>
                <Modal show={modalState} handleClose={toggleModal} close="">
                    <div className="csv-modal-contents">
                        <h2 className="csv-modal-title">Import Roster CSV Instructions</h2>
                        <h5>1. Download CSV Roster Templates</h5>
                        <Button variant="success" className="align-self-center csv-modal-item button-style" onClick={downloadAsCSV}>
                            Download CSV Template
                    </Button>
                        <h5>2. Edit template with roster information in Excel, Google Sheets, or similar software.</h5>
                        <h5>3. Export As CSV</h5>
                        <h5 className="csv-modal-item">4. Upload CSV File from your computer by selecting the "Choose File" button below.</h5>
                        <div className="csv-modal-item">
                            {modalState && (<>{renderFileSelect()}</>)}
                        </div>
                        <h5>5. Select "Import" to import your chosen file.</h5>
                        <Button variant="success" className="align-self-center submit button-style" onClick={determineInputDisplay}>
                            Import
                    </Button>
                    </div>
                </Modal>
            </div>
        </div>
    );
}

export default CSVUploader;
