/* eslint-disable max-len */
import React, { useEffect, useState, useContext } from 'react';
import {
  Form, Col, Row, Table, Button, InputGroup, FormControl, Spinner,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import mom from 'moment-timezone';
import DatePicker from 'react-datepicker';
import { DateTime } from 'luxon';
import MainLayout from '../../components/main-layout';
import apiWrapper from '../../api';
import Paginate from '../../components/paginate';
import GradeDownloader from '../../utils/grade-downloader';
import Modal from '../../components/modal';
import { selectedCourseIDTeacherInterface, setSelectedCourseIDTeacherInterface } from '../../utils/roster';
import './styles.css';
import { webAppContext } from '../../contexts/web-app-context';
import 'react-datepicker/dist/react-datepicker.css';

const tableTitles = [{
  title: 'Last name',
  value: 'last_name',
}, {
  title: 'First Name',
  value: 'first_name',
}, {
  title: 'Email',
  value: 'email',
}, {
  title: 'Average Stars',
  value: 'star_average',
}];

const ALL_COURSES_VALUE = 'ALL_COURSES';
const COLOR_THRESHOLDS = [
  { minValue: 1, color: '#D3A2A3' },
  { minValue: 2.56, color: '#FFF9A4' },
  { minValue: 2.79, color: 'transparent' },
];

const Overview = ({ history }) => {
  const [courses, setCourses] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState(0);
  const [selectedCourse, setSelectedCourse] = useState();
  const [students, setStudents] = useState();
  const [totalPages, setTotalPages] = useState();
  const [activePage, setActivePage] = useState(1);
  const [sortingParams, setSortingParams] = useState({
    title: 'last_name',
    orderDescending: true,
  });
  const [searchRequest, setSearchRequest] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [modalState, setModalState] = useState(false);
  const [actives, setActives] = useState([]);
  const [studentsLoading, setStudentsLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const { syllabus, assignments, lessonAssignments } = useContext(webAppContext);
  const [downloadType, setDownloadType] = useState('Percent Complete - All Assignments');
  const downloadTypes = [
    'Percent Complete - All Assignments',
    'Stars Earned - All Assignments',
    'Stars Earned Per Lesson - All Assignments',
    'Percent Complete - Assignment Set',
    'Stars Earned - Assignment Set',
    'Stars Earned Per Lesson - Assignment Set',
  ];
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [timezone, setTimezone] = useState(mom.tz.guess());
  const timezones = [...(mom.tz.zonesForCountry('US'))];
  const [allDataLoading, setAllDataLoading] = useState(true);
  const [downloadButtonText, setDownloadButtonText] = useState('Loading...');

  function convertTZ(date, tzString) {
    return new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', { timeZone: tzString }));
  }

  // DATA FETCHING
  useEffect(() => {
    apiWrapper.getCourses().then((res) => {
      setCourses(res.data);
      if (res.data.length === 1) {
        setSelectedCourseId(res.data[0].id);
        setSelectedCourseIDTeacherInterface(res.data[0].id);
        setSelectedCourse(res.data[0]);
        fetchStudentsData(1, sortingParams.title, 'asc', null, res.data[0].id);
      }
      if (selectedCourseIDTeacherInterface !== 0) {
        setStudentsLoading(true);
        setActivePage(1);
        setSelectedCourseId(selectedCourseIDTeacherInterface);
        setSearchRequest('');
        const courseFound = res.data.find(
          (course) => course.id === Number(selectedCourseIDTeacherInterface),
        );
        setSelectedCourse(courseFound);
        const courseId = courseFound ? courseFound.id : null;
        fetchStudentsData(1, sortingParams.title, 'asc', null, courseId);
        setStartDate(convertTZ(courseFound
          ? new Date(new Date(courseFound?.enrollment_start_date).setDate(new Date(courseFound?.enrollment_start_date).getDate() - 2))
          : new Date(startDate.setDate(startDate.getDate() - 2)), timezone));
        setEndDate(convertTZ(endDate, timezone));
        setAllDataLoading(false);
        setDownloadButtonText('Download');
      }
    });
  }, []);

  const toggleModal = () => {
    setModalState(!modalState);
  };

  const onChangeDownloadType = (e) => {
    setDownloadType(e.target.value);
  };

  const onChangeSelectTimezone = (e) => {
    setTimezone(e.target.value);
    const overrideZoneStart = DateTime.fromISO(new Date(selectedCourse.enrollment_start_date).toISOString(), { zone: e.target.value });
    setStartDate(new Date(overrideZoneStart.year, overrideZoneStart.month - 1, overrideZoneStart.day, overrideZoneStart.hour, overrideZoneStart.minute, overrideZoneStart.second));
    const overrideZoneEnd = DateTime.fromISO(new Date().toISOString(), { zone: e.target.value });
    setEndDate(new Date(overrideZoneEnd.year, overrideZoneEnd.month - 1, overrideZoneEnd.day, overrideZoneEnd.hour, overrideZoneEnd.minute, overrideZoneEnd.second));
  };

  const fetchingApiResponse = (res) => {
    setStudents(res.data.records);
    setStudentsLoading(false);
    setTotalPages(res.data.pagination.total_page);
  };

  const fetchStudentsData = (page, sortedBy, sortingOrder, query, courseId) => {
    if (!courseId) {
      if (query) {
        apiWrapper.globalSearchStudent(page, sortingParams.title, sortingOrder, query).then(fetchingApiResponse);
      } else if (actives.length === 0) fetchActiveStudents(page, sortedBy, sortingOrder);
      else paginateActiveStudents(page);
      return;
    }

    if (query) {
      apiWrapper.searchStudent(courseId, page, sortingParams.title, sortingOrder, query).then(fetchingApiResponse);
    } else {
      apiWrapper.getCourseStudents(courseId, page, sortedBy, sortingOrder).then(fetchingApiResponse);
    }
  };

  const paginateActiveStudents = (page) => {
    const paginatedStudents = []; let
      currPageStudents = [];
    actives.forEach((active) => {
      currPageStudents.push(active);
      if (currPageStudents.length === 10) {
        paginatedStudents.push(currPageStudents);
        currPageStudents = [];
      }
    });
    paginatedStudents.push(currPageStudents);
    setStudents(paginatedStudents[page - 1]);
    setTotalPages(Math.ceil(actives.length / 10));
    setStudentsLoading(false);
  };

  const fetchActiveStudents = async (page, sortedBy, sortingOrder) => {
    const activeStudents = actives;
    let teacherCourses = courses;
    if (!courses || courses.length === 0) {
      teacherCourses = await apiWrapper.getCourses();
      teacherCourses = teacherCourses.data;
      setCourses(teacherCourses);
    }
    for (const course of teacherCourses) {
      const isActive = moment().isBefore(course.end_date);
      if (isActive) {
        const courseRes = await apiWrapper.getCourseStudents(course.id, page, sortedBy, sortingOrder);
        activeStudents.push(...courseRes.data.records);
        setActives(activeStudents);
      }
    }
    paginateActiveStudents(page);
  };

  // COURSE SELECTION
  const onCourseChangeId = async (e) => {
    setStudentsLoading(true);
    setAllDataLoading(true);
    setDownloadButtonText('Loading...');
    setActivePage(1);
    setSelectedCourseId(e.target.value);
    setSelectedCourseIDTeacherInterface(e.target.value);
    setSearchRequest('');
    const courseFound = courses.find(
      (course) => course.id === Number(e.target.value),
    );
    setSelectedCourse(courseFound);
    const courseId = courseFound ? courseFound.id : null;
    fetchStudentsData(1, sortingParams.title, 'asc', null, courseId);
    if (courseFound) {
      const [, userIdString] = await getUsers(courseFound);
      let start;
      try {
        const submissions = await apiWrapper.getAllSubmissionsForCourse(courseFound.id, userIdString);
        start = submissions?.data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      } finally {
        const courseStart = convertTZ(start?.length > 0 ? new Date(start[0].created_at) : new Date(courseFound.enrollment_start_date), timezone);
        setStartDate((courseStart > new Date(courseFound?.enrollment_start_date))
          ? new Date(new Date(courseFound?.enrollment_start_date).setDate(new Date(courseFound?.enrollment_start_date).getDate() - 2))
          : new Date(courseStart.setDate(courseStart.getDate() - 2)));
        setEndDate(convertTZ(endDate, timezone));
        setAllDataLoading(false);
        setDownloadButtonText('Download');
      }
    }
  };

  // PAGINATION
  const changePageFunction = (number) => {
    setActivePage(number);
    const sortingOrderName = sortingParams.order ? 'desc' : 'asc';
    const courseId = selectedCourse ? selectedCourse.id : null;
    const query = searchRequest || null;
    fetchStudentsData(number, sortingParams.title, sortingOrderName, query, courseId);
  };

  // SORTING
  const onSort = (sortedBy) => {
    const sortingOrder = sortingParams.title === sortedBy ? !sortingParams.order : false;
    setSortingParams({
      title: sortedBy,
      order: sortingOrder,
    });
    const sortingOrderName = sortingOrder ? 'desc' : 'asc';
    const courseId = selectedCourse ? selectedCourse.id : null;
    const query = searchRequest || null;
    fetchStudentsData(1, sortedBy, sortingOrderName, query, courseId);
  };

  // STUDENT SEARCH
  const onChangeSearchRequest = (e) => {
    setStudentsLoading(true);
    if (typingTimeout) clearTimeout(typingTimeout);
    setSearchRequest(e.target.value);
    const query = e.target.value;
    const sortingOrderName = sortingParams.orderDescending ? 'desc' : 'asc';
    setTypingTimeout(
      setTimeout(() => {
        const courseId = selectedCourse ? selectedCourseId : null;
        fetchStudentsData(1, sortingParams.title, sortingOrderName, query, courseId);
      }, 800),
    );
  };

  // RENDERING
  const getColorForAverageStars = (value) => COLOR_THRESHOLDS.reduce((acc, threshold) => {
    if (value > threshold.minValue) {
      return threshold.color;
    }
    return acc;
  }, 'transparent');

  const renderCourses = () => courses.map((course) => (
    <option key={course.id} value={course.id}>
      {course.name}
    </option>
  ));

  // PERCENTAGES
  const calculateStars = (submissions, assignmentSet) => {
    if (assignmentSet) {
      const assignmentSetSubmissions = [];
      selectedCourse.assignment_set.assignments.forEach((ass) => {
        const foundSubmission = submissions.find((sub) => sub.lesson_assignment_id === ass.id);
        assignmentSetSubmissions.push(foundSubmission);
      });
      return assignmentSetSubmissions?.reduce((acc, submission) => {
        if (!submission?.is_correct) return acc;
        if (submission.did_peek) return acc + 1;
        if (submission.did_look_at_hint) return acc + 2;
        return acc + 3;
      }, 0) ?? 0;
    }
    return submissions?.reduce((acc, submission) => {
      if (!submission.is_correct) return acc;
      if (submission.did_peek) return acc + 1;
      if (submission.did_look_at_hint) return acc + 2;
      return acc + 3;
    }, 0) ?? 0;
  };

  const calculateStarsPerLesson = (submissionsByLesson) => {
    const starsPerLesson = {
      intro: 0,
      rot: 0,
      iso: 0,
      ortho: 0,
      _2d3d: 0,
      slopes: 0,
      flat: 0,
      rot1: 0,
      rot2: 0,
      assembly: 0,
    };
    for (const lesson in submissionsByLesson) {
      let stars = 0;
      submissionsByLesson[lesson].forEach((submission) => {
        if (!submission.is_correct) stars += 0;
        else if (submission.is_correct) {
          if (submission.did_peek) stars += 1;
          else if (submission.did_look_at_hint) stars += 2;
          else stars += 3;
        }
      });
      starsPerLesson[lesson] = stars;
    }
    return starsPerLesson;
  };

  const getStars = (submission) => {
    if (!submission?.is_correct) return '';
    if (submission.is_correct) {
      if (submission.did_peek) return 1;
      if (submission.did_look_at_hint) return 2;
      if (!submission.did_peek && !submission.did_look_at_hint) return 3;
    }
  };

  const getLessonSolidTotals = () => syllabus.reduce((acc, lesson) => ({
    ...acc,
    [lesson.code]: assignments.filter(
      (ass) => {
        const code = ass.code.split('_')[0];
        return code === lesson.code && (ass.kind === 'solid' || ass.kind === 'hidden');
      },
    ).length,
  }), {});

  const getLessonAssignmentSetTotals = () => lessonAssignments.reduce((acc, lesson) => ({
    ...acc,
    [lesson.lesson_code]: selectedCourse?.assignment_set?.assignments.filter(
      (ass) => {
        const code = ass.lesson_code.split('_')[0];
        return code === lesson.lesson_code;
      },
    ).length,
  }), {});

  const getAssignmentsCompleted = (submissions) => syllabus.reduce((acc, lesson) => ({
    ...acc,
    [lesson.code]: submissions.reduce((subAcc, sub) => {
      const lessonAssignment = assignments.find((item) => item.id === sub.lesson_assignment_id);
      if (lessonAssignment) {
        const code = lessonAssignment.code.split('_')[0];
        if (sub.is_correct && code === lesson.code) return subAcc + 1;
      }
      return subAcc;
    }, 0),
  }), {});

  const getAssignmentsCompletedAssignmentSet = (submissions) => lessonAssignments.reduce((acc, lesson) => ({
    ...acc,
    [lesson.lesson_code]: submissions.reduce((subAcc, sub) => {
      const lessonAssignment = selectedCourse?.assignment_set.assignments.find((item) => item.id === sub.lesson_assignment_id);
      if (lessonAssignment) {
        const code = lessonAssignment.lesson_code.split('_')[0];
        if (sub.is_correct && code === lesson.lesson_code) return subAcc + 1;
      }
      return subAcc;
    }, 0),
  }), {});

  const getPercentages = (submissions, assignmentSet = false) => {
    const assignmentsCompleted = assignmentSet ? getAssignmentsCompletedAssignmentSet(submissions) : getAssignmentsCompleted(submissions);
    if (assignmentSet) {
      const lessonAssignmentSetTotals = getLessonAssignmentSetTotals();
      return {
        intro: lessonAssignmentSetTotals.intro !== 0 ? Math.round((assignmentsCompleted.intro / lessonAssignmentSetTotals.intro) * 100) : 0,
        rot: lessonAssignmentSetTotals.rot !== 0 ? Math.round((assignmentsCompleted.rot / lessonAssignmentSetTotals.rot) * 100) : 0,
        iso: lessonAssignmentSetTotals.iso !== 0 ? Math.round((assignmentsCompleted.iso / lessonAssignmentSetTotals.iso) * 100) : 0,
        ortho: lessonAssignmentSetTotals.ortho !== 0 ? Math.round((assignmentsCompleted.ortho / lessonAssignmentSetTotals.ortho) * 100) : 0,
        '2D3D': lessonAssignmentSetTotals['2D3D'] !== 0 ? Math.round((assignmentsCompleted['2D3D'] / lessonAssignmentSetTotals['2D3D']) * 100) : 0,
        slopes: lessonAssignmentSetTotals.slopes !== 0 ? Math.round((assignmentsCompleted.slopes / lessonAssignmentSetTotals.slopes) * 100) : 0,
        flat: lessonAssignmentSetTotals.flat !== 0 ? Math.round((assignmentsCompleted.flat / lessonAssignmentSetTotals.flat) * 100) : 0,
        rot1: lessonAssignmentSetTotals.rot1 !== 0 ? Math.round((assignmentsCompleted.rot1 / lessonAssignmentSetTotals.rot1) * 100) : 0,
        rot2: lessonAssignmentSetTotals.rot2 !== 0 ? Math.round((assignmentsCompleted.rot2 / lessonAssignmentSetTotals.rot2) * 100) : 0,
        assembly: lessonAssignmentSetTotals.assembly !== 0 ? Math.round((assignmentsCompleted.assembly / lessonAssignmentSetTotals.assembly) * 100) : 0,
      };
    }
    const lessonSolidTotals = getLessonSolidTotals();
    return {
      intro: lessonSolidTotals.intro !== 0 ? Math.round((assignmentsCompleted.intro / lessonSolidTotals.intro) * 100) : 0,
      rot: lessonSolidTotals.rot !== 0 ? Math.round((assignmentsCompleted.rot / lessonSolidTotals.rot) * 100) : 0,
      iso: lessonSolidTotals.iso !== 0 ? Math.round((assignmentsCompleted.iso / lessonSolidTotals.iso) * 100) : 0,
      ortho: lessonSolidTotals.ortho !== 0 ? Math.round((assignmentsCompleted.ortho / lessonSolidTotals.ortho) * 100) : 0,
      '2D3D': lessonSolidTotals['2D3D'] !== 0 ? Math.round((assignmentsCompleted['2D3D'] / lessonSolidTotals['2D3D']) * 100) : 0,
      slopes: lessonSolidTotals.slopes !== 0 ? Math.round((assignmentsCompleted.slopes / lessonSolidTotals.slopes) * 100) : 0,
      flat: lessonSolidTotals.flat !== 0 ? Math.round((assignmentsCompleted.flat / lessonSolidTotals.flat) * 100) : 0,
      rot1: lessonSolidTotals.rot1 !== 0 ? Math.round((assignmentsCompleted.rot1 / lessonSolidTotals.rot1) * 100) : 0,
      rot2: lessonSolidTotals.rot2 !== 0 ? Math.round((assignmentsCompleted.rot2 / lessonSolidTotals.rot2) * 100) : 0,
      assembly: lessonSolidTotals.assembly !== 0 ? Math.round((assignmentsCompleted.assembly / lessonSolidTotals.assembly) * 100) : 0,
    };
  };

  // DOWNLOAD
  const getUsers = async (currentCourse) => {
    const course = currentCourse;
    let users = [];
    let userIdString = '';
    let total = 0;
    if (!totalPages) {
      total = await apiWrapper.getCourseStudents(course.id, 1, sortingParams.title, 'asc');
      total = total.data.pagination.total_page;
    } else total = totalPages;
    for (let page = 1; page <= total; page += 1) {
      const response = await apiWrapper.getCourseStudents(course.id, page, sortingParams.title, 'asc');
      users = users.concat(response.data.records);
    }
    users.forEach((student, index) => {
      if (index === users.length - 1) userIdString += student.id;
      else userIdString += `${student.id}_`;
    });
    return [users, userIdString];
  };

  const sortByLastName = (info) => info.sort((a, b) => {
    const aName = a.last_name.toLowerCase() + a.first_name.toLowerCase();
    const bName = b.last_name.toLowerCase() + b.first_name.toLowerCase();
    return aName.localeCompare(bName);
  });

  const getPercentComplete = async (assignmentSet) => {
    const percentages = [];
    const [users, userIdString] = await getUsers(selectedCourse);
    const studentsAdded = [];
    if (users.length > 0) {
      const submissions = await apiWrapper.getAllSubmissionsForCourse(selectedCourse.id, userIdString);
      const filteredSubmissions = await filterSubmissionsByTime(submissions.data);
      const enrollmentIDs = [...new Set(filteredSubmissions.map((sub) => sub.enrollment_id))];
      for (const id of enrollmentIDs) {
        const currentSubmissions = filteredSubmissions.filter((sub) => sub.enrollment_id === id);
        const currentPercentages = getPercentages(currentSubmissions, assignmentSet);
        const currentStudent = users.find((student) => student.id === currentSubmissions[0].user_id);
        studentsAdded.push(currentStudent);
        const totalStars = calculateStars(currentSubmissions, assignmentSet);
        percentages.push({
          last_name: currentStudent?.last_name,
          first_name: currentStudent?.first_name,
          email: currentStudent.email ? currentStudent.email : currentStudent.username,
          user_id: currentStudent?.id,
          intro: currentPercentages?.intro,
          rot: currentPercentages?.rot,
          iso: currentPercentages?.iso,
          ortho: currentPercentages?.ortho,
          _2d3d: currentPercentages['2D3D'],
          slopes: currentPercentages?.slopes,
          flat: currentPercentages?.flat,
          rot1: currentPercentages?.rot1,
          rot2: currentPercentages?.rot2,
          assembly: currentPercentages?.assembly,
          stars: totalStars,
        });
      }
    }
    const usersWithoutSubmissions = users.filter((x) => !studentsAdded.includes(x));
    usersWithoutSubmissions.forEach((user) => {
      percentages.push({
        last_name: user.last_name,
        first_name: user.first_name,
        email: user.email ? user.email : user.username,
        user_id: user.id,
        intro: 0,
        rot: 0,
        iso: 0,
        ortho: 0,
        _2d3d: 0,
        slopes: 0,
        flat: 0,
        rot1: 0,
        rot2: 0,
        assembly: 0,
        stars: 0,
      });
    });
    return sortByLastName(percentages);
  };

  const getStarsEarned = async (assignmentSet) => {
    const starsEarned = [];
    const [users, userIdString] = await getUsers(selectedCourse);
    const studentsAdded = [];
    if (users.length > 0) {
      const submissions = await apiWrapper.getAllSubmissionsForCourse(selectedCourse.id, userIdString);
      const filteredSubmissions = await filterSubmissionsByTime(submissions.data);
      const enrollmentIDs = [...new Set(filteredSubmissions.map((sub) => sub.enrollment_id))];
      for (const id of enrollmentIDs) {
        const currentSubmissions = filteredSubmissions.filter((sub) => sub.enrollment_id === id);
        const currentStudent = users.find((student) => student.id === currentSubmissions[0].user_id);
        studentsAdded.push(currentStudent);
        const currentStars = {
          last_name: currentStudent?.last_name,
          first_name: currentStudent?.first_name,
          email: currentStudent.email ? currentStudent.email : currentStudent.username,
          user_id: currentStudent?.id,
        };
        let total = 0;
        if (assignmentSet) {
          selectedCourse.assignment_set.assignments.forEach((ass) => {
            const assignmentSubmission = currentSubmissions.find((sub) => sub.lesson_assignment_id === ass.id);
            const stars = getStars(assignmentSubmission);
            if (ass.lesson_code === '2D3D') currentStars[`_2d3d_${ass.display_order}`] = stars;
            else currentStars[`${ass.lesson_code}_${ass.display_order}`] = stars;
            if (stars !== '') total += stars;
          });
        } else {
          assignments.forEach((ass) => {
            if (ass.kind === 'solid' || ass.kind === 'hidden') {
              const assignmentSubmission = currentSubmissions.find((sub) => sub.lesson_assignment_id === ass.id);
              const stars = getStars(assignmentSubmission);
              if (ass.code.split('_')[0] === '2D3D') currentStars[`_2d3d_${ass.display_order}`] = stars;
              else currentStars[`${ass.code.split('_')[0]}_${ass.display_order}`] = stars;
              if (stars !== '') total += stars;
            }
          });
        }
        currentStars.total = total;
        starsEarned.push(currentStars);
      }
    }
    const usersWithoutSubmissions = users.filter((x) => !studentsAdded.includes(x));
    usersWithoutSubmissions.forEach((user) => {
      const currentStars = {
        last_name: user.last_name,
        first_name: user.first_name,
        email: user.email ? user.email : user.username,
        user_id: user.id,
      };
      if (assignmentSet) {
        selectedCourse.assignment_set.assignments.forEach((ass) => {
          if (ass.lesson_code === '2D3D') currentStars[`_2d3d_${ass.display_order}`] = '';
          else currentStars[`${ass.lesson_code}_${ass.display_order}`] = '';
        });
      } else {
        assignments.forEach((ass) => {
          if (ass.kind === 'solid' || ass.kind === 'hidden') {
            if (ass.code.split('_')[0] === '2D3D') currentStars[`_2d3d_${ass.display_order}`] = '';
            else currentStars[`${ass.code.split('_')[0]}_${ass.display_order}`] = '';
          }
        });
      }
      currentStars.total = 0;
      starsEarned.push(currentStars);
    });
    return sortByLastName(starsEarned);
  };

  const getStarsEarnedPerLesson = async (assignmentSet) => {
    const starsPerLesson = [];
    const [users, userIdString] = await getUsers(selectedCourse);
    const studentsAdded = [];
    if (users.length > 0) {
      const submissions = await apiWrapper.getAllSubmissionsForCourse(selectedCourse.id, userIdString);
      const filteredSubmissions = await filterSubmissionsByTime(submissions.data);
      const enrollmentIDs = [...new Set(filteredSubmissions.map((sub) => sub.enrollment_id))];
      for (const id of enrollmentIDs) {
        const currentSubmissions = filteredSubmissions.filter((sub) => sub.enrollment_id === id);
        const currentStudent = users.find((student) => student.id === currentSubmissions[0].user_id);
        studentsAdded.push(currentStudent);
        const submissionsByLesson = {
          intro: [],
          rot: [],
          iso: [],
          ortho: [],
          '2D3D': [],
          slopes: [],
          flat: [],
          rot1: [],
          rot2: [],
          assembly: [],
        };
        currentSubmissions.forEach((sub) => {
          const currentLesson = assignmentSet ? selectedCourse.assignment_set.assignments.find((assignment) => assignment.id === sub.lesson_assignment_id)
            : lessonAssignments[sub.lesson_assignment_id - 1];
          if (currentLesson) submissionsByLesson[currentLesson?.lesson_code].push(sub);
        });
        const currentStarsPerLesson = calculateStarsPerLesson(submissionsByLesson);
        starsPerLesson.push({
          last_name: currentStudent?.last_name,
          first_name: currentStudent?.first_name,
          email: currentStudent.email ? currentStudent.email : currentStudent.username,
          user_id: currentStudent?.id,
          intro: currentStarsPerLesson?.intro,
          rot: currentStarsPerLesson?.rot,
          iso: currentStarsPerLesson?.iso,
          ortho: currentStarsPerLesson?.ortho,
          _2d3d: currentStarsPerLesson['2D3D'],
          slopes: currentStarsPerLesson?.slopes,
          flat: currentStarsPerLesson?.flat,
          rot1: currentStarsPerLesson?.rot1,
          rot2: currentStarsPerLesson?.rot2,
          assembly: currentStarsPerLesson?.assembly,
        });
      }
    }
    const usersWithoutSubmissions = users.filter((x) => !studentsAdded.includes(x));
    usersWithoutSubmissions.forEach((user) => {
      starsPerLesson.push({
        last_name: user.last_name,
        first_name: user.first_name,
        email: user.email ? user.email : user.username,
        user_id: user.id,
        intro: 0,
        rot: 0,
        iso: 0,
        ortho: 0,
        _2d3d: 0,
        slopes: 0,
        flat: 0,
        rot1: 0,
        rot2: 0,
        assembly: 0,
      });
    });
    return sortByLastName(starsPerLesson);
  };

  const onDownloadStarsEarned = async (includeAssignmentSet = false) => {
    getStarsEarned(includeAssignmentSet).then((stars) => new Promise(() => {
      setTimeout(() => {
        GradeDownloader.downloadStarsEarned(selectedCourse, includeAssignmentSet, stars);
        setDownloading(false);
      }, 1000);
    }));
  };

  const onDownloadPercentComplete = async (includeAssignmentSet = false) => {
    getPercentComplete(includeAssignmentSet).then((percentages) => new Promise(() => {
      setTimeout(() => {
        GradeDownloader.downloadPercentComplete(selectedCourse, includeAssignmentSet, percentages);
        setDownloading(false);
      }, 1000);
    }));
  };

  const onDownloadStarsPerLesson = async (includeAssignmentSet = false) => {
    getStarsEarnedPerLesson(includeAssignmentSet).then((stars) => new Promise(() => {
      setTimeout(() => {
        GradeDownloader.downloadStarsEarnedPerLesson(selectedCourse, includeAssignmentSet, stars);
        setDownloading(false);
      }, 1000);
    }));
  };

  const filterSubmissionsByTime = (submissions) => {
    const filteredSubmissions = [];
    submissions.forEach((sub) => {
      const createdAt = DateTime.fromISO(sub.created_at, { zone: timezone });
      const updatedAt = DateTime.fromISO(sub.updated_at, { zone: timezone });
      const createdAtFormatted = new Date(createdAt.year, createdAt.month - 1, createdAt.day, createdAt.hour, createdAt.minute, createdAt.second);
      const updatedAtFormatted = new Date(updatedAt.year, updatedAt.month - 1, updatedAt.day, updatedAt.hour, updatedAt.minute, updatedAt.second);
      if ((createdAtFormatted.toISOString() >= startDate.toISOString() && createdAtFormatted.toISOString() <= endDate.toISOString())
      || (updatedAtFormatted.toISOString() >= startDate.toISOString() && updatedAtFormatted.toISOString() <= endDate.toISOString())) filteredSubmissions.push(sub);
    });
    return filteredSubmissions;
  };

  const onDownload = (e) => {
    setDownloading(true);
    e.preventDefault();
    switch (downloadType) {
      case 'Percent Complete - All Assignments':
        onDownloadPercentComplete(false);
        break;
      case 'Stars Earned - All Assignments':
        onDownloadStarsEarned(false);
        break;
      case 'Stars Earned Per Lesson - All Assignments':
        onDownloadStarsPerLesson(false);
        break;
      case 'Percent Complete - Assignment Set':
        onDownloadPercentComplete(true);
        break;
      case 'Stars Earned - Assignment Set':
        onDownloadStarsEarned(true);
        break;
      case 'Stars Earned Per Lesson - Assignment Set':
        onDownloadStarsPerLesson(true);
        break;
      default:
        break;
    }
  };

  const renderDownloadTypes = () => downloadTypes.map((download) => {
    if (download.indexOf('Assignment Set') >= 0) {
      if (selectedCourse.assignment_set) {
        return (
          <option key={download} value={download}>{download}</option>
        );
      }
    } else {
      return (
        <option key={download} value={download}>{download}</option>
      );
    }
  });

  const renderTeachers = () => selectedCourse.teachers.map((teacher) => (
    <div>
      <span
        key={teacher.id}
      >
        {`${teacher.last_name}, ${teacher.first_name} (${teacher.email})`}
      </span>
    </div>
  ));

  const renderCourseStatus = () => {
    const isActive = moment().isBefore(selectedCourse.end_date);
    return (
      <b className={`ml-2 ${isActive ? 'text-success' : 'text-danger'}`}>
        {isActive ? 'ACTIVE' : 'INACTIVE'}
      </b>
    );
  };

  const renderStudentTableHeader = () => tableTitles.map((item, index) => {
    const isChosen = sortingParams.title === item.value;

    let sortUpColor;
    let sortDownColor;
    if (isChosen) {
      const isDesc = sortingParams.order;
      if (isDesc) {
        sortUpColor = 'rgba(255, 255, 255, 0.4)';
        sortDownColor = 'white';
      } else {
        sortUpColor = 'white';
        sortDownColor = 'rgba(255, 255, 255, 0.4)';
      }
    } else {
      sortUpColor = 'rgba(255, 255, 255, 0.4)';
      sortDownColor = 'rgba(255, 255, 255, 0.4)';
    }
    return (
      <th key={index}>
        <Button
          className="d-flex align-items-center bg-transparent shadow-none border-0"
          size="sm"
          onClick={() => {
            onSort(item.value);
          }}
        >
          <b>
            {item.title}
          </b>
          <div className="d-flex flex-column align-items-center ml-2">
            <FontAwesomeIcon icon={faSortUp} color={sortUpColor} />
            <FontAwesomeIcon
              icon={faSortDown}
              color={sortDownColor}
              style={{ marginTop: -12 }}
            />
          </div>
        </Button>
      </th>
    );
  });

  const renderStudentsTable = () => students.map((student, index) => {
    const idx = index + 1 + 10 * (activePage - 1);
    return (
      <tr key={student.id}>
        <td>{idx}</td>
        <td>{student.last_name}</td>
        <td>{student.first_name}</td>
        {student.email ? (<><td>{student.email}</td></>) : (<><td>{student.username}</td></>)}
        <td style={{
          backgroundColor: getColorForAverageStars(student.star_average),
          textAlign: 'center',
        }}
        >
          {student.star_average.toFixed(2)}
        </td>
      </tr>
    );
  });

  const renderTimezoneOptions = () => timezones.map((zone, idx) => (
    <option key={idx} value={zone}>{zone}</option>
  ));

  return (
    <MainLayout history={history}>
      <Row>
        <Col sm={12} lg={6}>
          <div className="d-flex mt-4">
            <h2>Course</h2>
            <Form
              style={{
                display: 'flex',
                alignItems: 'center',
                flex: 1,
                marginLeft: 40,
              }}
            >
              <Form.Control
                as="select"
                name="course"
                value={selectedCourseId}
                onChange={onCourseChangeId}
              >
                <option disabled value={0}>
                  Select option
                </option>
                {courses.length > 1 && (
                  <option value={ALL_COURSES_VALUE}>
                    All Active Students
                  </option>
                )}
                {renderCourses()}
              </Form.Control>
            </Form>
          </div>
        </Col>
      </Row>

      {selectedCourse && (
        <>
          <Row className="mt-2 ml-5 course-info">
            <Col sm={12} lg={6} className="d-flex justify-content-between">
              <div className="d-flex mr-4">
                <span>Course Invite Code:</span>
                <b className="ml-2 course-details">{selectedCourse.code}</b>
              </div>
              <div className="d-flex">
                <span>Course Status:</span>
                <b className="ml-2 text-success course-details">{renderCourseStatus()}</b>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <h2>Instructor(s)</h2>
            </Col>
          </Row>
          <Row className="ml-5 mt-2">
            <Col>{renderTeachers()}</Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <h2>Download CSV Grade Reports</h2>
            </Col>
          </Row>
          <Form>
            <Form.Row className="mt-3">
              <Form.Group as={Col}>
                <Form.Label>Choose the information you would like to download:*</Form.Label>
                <Form.Control as="select" name="download_type" value={downloadType} onChange={onChangeDownloadType} required>
                  <option disabled value={0}>Select option</option>
                  {renderDownloadTypes()}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row style={{ marginBottom: '-10px' }}>
              <Form.Group as={Col}>
                <Form.Label>Option to limit download to specific date range:</Form.Label>
              </Form.Group>
            </Form.Row>
          </Form>
          <Row className="mb-3">
            <Col md={4}>
              <div>Start Date (default is class start date)</div>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                timeInputLabel="Time:"
                dateFormat="MM/dd/yyyy h:mm aa"
                showTimeInput
                className="date-time"
              />
            </Col>
            <Col md={4}>
              <div>End Date (typically due date)</div>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                timeInputLabel="Time:"
                dateFormat="MM/dd/yyyy h:mm aa"
                showTimeInput
                className="date-time"
              />
            </Col>
            <Col md={4}>
              <Form>
                <Form.Group>
                  <div>Select a timezone:</div>
                  <Form.Control as="select" name="timezone" value={timezone} onChange={onChangeSelectTimezone} required>
                    <option disabled value={0}>Select option</option>
                    {renderTimezoneOptions()}
                  </Form.Control>
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Button
            variant="success"
            className="submit button-style roster-button mb-4"
            onClick={onDownload}
            disabled={allDataLoading}
          >
            {downloadButtonText}
            &nbsp;
            {(downloading
              || allDataLoading)
              && (
              <Spinner size="sm" animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
              )}
          </Button>
        </>
      )}
      {students
        && (
        <Row className="mt-3">
          <Col>
            <div className="d-flex">
              <h2>Students</h2>
              <InputGroup className="ml-4">
                <FormControl
                  placeholder="Find a student"
                  aria-label="Find a student"
                  value={searchRequest}
                  onChange={onChangeSearchRequest}
                />
              </InputGroup>
            </div>
          </Col>
        </Row>
        )}
      {studentsLoading
        ? (
          <div className="students-spinner">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )
        : (
          <>
            {students && (
              <>
                <Row>
                  <Col>

                    <>
                      <Table responsive className="mt-4">
                        <thead>
                          <tr
                            className="text-white"
                            style={{ backgroundColor: 'hsl(200, 92%, 51%)' }}
                          >
                            <th />
                            {renderStudentTableHeader()}
                          </tr>
                        </thead>
                        <tbody>{renderStudentsTable()}</tbody>
                      </Table>
                      {students.length > 0 && totalPages ? (
                        <>
                          <Paginate totalPages={totalPages} changePageFunction={changePageFunction} currPage={activePage} />
                        </>
                      ) : (
                        <>
                          {selectedCourse && selectedCourse.enrollment_type !== 'self' ? (
                            <>
                              <Button
                                variant="success"
                                className="align-self-center roster-button submit button-style"
                                onClick={() => { history.push('/rostering', { course: selectedCourse }); }}
                              >
                                Add Your Course Roster
                              </Button>
                            </>
                          ) : (
                            <>
                              {students.length === 0 && selectedCourse && (
                                <>
                                  <h6>No students have enrolled in your course. Please provide your students with the student enrollment instructions below.</h6>
                                  <Button
                                    variant="success"
                                    className="submit button-style roster-button"
                                    onClick={toggleModal}
                                  >
                                    Student Enrollment & Activation Instructions
                                  </Button>
                                </>
                              )}
                              {students.length === 0 && !selectedCourse && (
                                <><h6>There are no active students currently enrolled in your courses.</h6></>
                              )}
                              {selectedCourse && (
                              <Modal show={modalState} handleClose={toggleModal} close="button">
                                <h2>{`${selectedCourse.name} Student Enrollment & Activation Instructions`}</h2>
                                <h6>Provide your students with the following Spatial Vis enrollment instructions:</h6>
                                <ol>
                                  <li>
                                    <h6>
                                      Visit the eGrove Education website:
                                      <a target="_blank" href="https://egrove.education/" rel="noopener noreferrer" className="link">https://egrove.education/</a>
                                    </h6>
                                  </li>
                                  <li><h6>Click the "Enroll/Sign Up" button in the upper right corner.</h6></li>
                                  <li><h6>Click "Sign Up"</h6></li>
                                  <li><h6>Create an account by entering your First Name, Last Name, Email Address, and Password. Then click “Sign up”.</h6></li>
                                  <li><h6>Verify your email address by logging into your account email. You should have a “Welcome to Spatial Vis!” email. Open the email and select the green “Confirm your account" button. If the button does not appear, copy and paste the URL at the bottom of your email.</h6></li>
                                  <li>
                                    <h6>
                                      You will be redirected to log in to enroll in a course. Log in using your Email and Password from your new account and click submit. If you are not redirected to log in, visit the eGrove Education website (
                                      <a target="_blank" href="https://egrove.education/" rel="noopener noreferrer" className="link">https://egrove.education/</a>
                                      ) and click the “Enroll/Sign Up” button in the upper right corner. Log in to your account.
                                    </h6>
                                  </li>
                                  <li>
                                    <h6>
                                      <p>
                                        Enter the following course invite code:
                                        <b>{selectedCourse.code}</b>
                                      </p>
                                    </h6>
                                  </li>
                                  <li><h6>If the course looks correct, proceed to the payment screen by selecting “Checkout”.</h6></li>
                                  <li><h6>Enter your credit card information and select pay. Now you are enrolled in the course.</h6></li>
                                  <li>
                                    <h6>
                                      You may now log in to the Spatial Vis mobile app on your Chromebook, Tablet, or Phone using the Apple App or Google Play app. You may also log in from your computer by logging in on our website at
                                      <a target="_blank" href="https://egrove.education/" rel="noopener noreferrer" className="link">https://egrove.education/</a>
                                      .
                                    </h6>
                                  </li>
                                </ol>
                                <h6>Contact info@egrove.education if you have any questions.</h6>
                              </Modal>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  </Col>
                </Row>
              </>
            )}

          </>
        )}
    </MainLayout>
  );
};

export default Overview;
